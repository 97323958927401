import "../../styles/waiver.css";

const Waiver = () => {
  return (
    <div>
      <p className="p2">
        <br />
      </p>
      <p className="p1">
        Your health and safety is important to us. lululemon continually
        monitors developing federal, state/provincial and local public health
        and safety recommendations and requirements to determine the appropriate
        safety protocols for the Activities (defined below).
        <span className="Apple-converted-space">&nbsp;</span>
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">I acknowledge and agree that:</p>
      <p className="p2">
        <br />
      </p>
      <ol className="ol1">
        <li className="li1">
          I shall comply with all guidelines detailed in the Event
          communications, on the Event website (if applicable), and/or shared at
          the Event through signs and other means, as well as any
          state/provincial or local rules or guidelines and recommendations
          applicable to my participation in the Activity at that time,
          including, without limitation, COVID-19 vaccination mandates, social
          distancing and face masking requirements/mandates.
        </li>
        <li className="li1">
          lululemon cannot guarantee that I will not be exposed to or become
          infected with COVID-19.
        </li>
      </ol>
      <p className="p2">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          In consideration of joining in the above-described event(s), classes,
          activities, or other programs, and using the premises, facilities,
          and/or equipment, or any other location or venue where lululemon is
          providing services (the &ldquo;<strong>Activity</strong>&rdquo; or
          &ldquo;<strong>Activities</strong>&rdquo;), I agree and acknowledge
          that I am fully aware that there are significant inherent risks and
          dangers involved in physical training, yoga, and running exercise
          activities, including the potential of death, injury, disability,
          short-term and long-term health effects and/or property damage.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I accept
          all the risks of participating, even if the risks are created by the
          carelessness, negligence or gross negligence of a Released Party (as
          defined below) or anyone else.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </li>
      </ol>
      <p className="p4">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          &ldquo;<strong>Claims</strong>&rdquo; includes but is not limited to
          any and all liabilities, claims, demands, legal actions, rights of
          actions for damages, costs, procedures, personal injury or death in
          connection with participation in the Activity, including being exposed
          to or infected by communicable diseases (including, without
          limitation, COVID-19).
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>&ldquo;
          <strong>Released Party</strong>&rdquo; means lululemon athletica
          canada inc., lululemon usa inc., and all of their affiliates,
          franchisees and their respective representatives, directors, officers,
          agents, employees and volunteer staff.
          <span className="Apple-converted-space">&nbsp;</span>
        </li>
      </ol>
      <p className="p5">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I agree and acknowledge that: (a) I am in proper physical and mental
          condition to participate in the Activities, and am aware that
          participation could, in some circumstances, result in physical injury,
          serious physical injury or death; (b) I understand my physical
          limitations and am sufficiently self-aware to stop physical activity
          before I become ill or injured; (c) if, in the subjective opinion of
          any Released Party, I would be at risk by participating in the
          Activities, I may be denied access to the Activities and related
          facilities until I furnish an opinion letter from my medical
          physician, at my sole cost and expense, specifically addressing the
          Released Party&rsquo;s concerns and stating that the Released
          Party&rsquo;s concerns are unfounded; (d) at all times I shall comply
          with all stated and customary terms, posted safety signs, rules, and
          verbal instructions given to me by any Released Party;
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>(e) I am
          aware that if the Activities occur outdoors, the streets adjourning
          the area of the Activities are open to regular vehicular traffic
          during the Activities and I will obey all traffic laws and
          regulations; (f)<span className="s2">&nbsp;</span>I understand that I
          am under no obligation to participate in the Activities and it is my
          choice to both participate and attend; and (g) my participation in the
          Activity or Activities may include an increased risk of exposure to or
          infection by communicable diseases (including, without limitation,
          COVID-19).<span className="Apple-converted-space">&nbsp;&nbsp;</span>I
          further understand that{" "}
          <span className="s3">
            I am solely responsible for my own safety and physical and mental
            health and wellbeing and that my compliance with applicable public
            health and safety rules, guidelines and recommendations, including
            observing social distancing and face masking requirements and/or
            mandates, and my participation in the Activities are completely
            voluntary and optional.
          </span>
        </li>
      </ol>
      <p className="p5">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I hereby, for myself and for my heirs, next of kin, executors,
          administrators and assigns, fully and irrevocably release, waive,
          forever discharge and agree not to assert, any and all rights or
          Claims I may have, now or in the future, against any Released Party,
          even if the Claims are based on the carelessness, negligence or gross
          negligence of a Released Party or anyone else.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>Without
          limiting the foregoing, I further irrevocably release, waive and agree
          not to assert any recourses which I may now or hereafter have
          resulting from any decision or requirement of any Governmental Entity
          or Released Party, including, without limitation, any vaccination,
          masking, social distancing, or other COVID-19-related public health or
          safety requirement with which I must personally comply.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>For
          purposes of this Agreement, the term &ldquo;
          <strong>Governmental Entity</strong>&rdquo; means any government or
          any agency, bureau, board, commission, court, department, official,
          political subdivision, tribunal or other instrumentality of any
          government, whether federal, state, provincial or local, domestic or
          foreign..
        </li>
      </ol>
      <p className="p5">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I agree not to sue any Released Party for Claims, even if the Claims
          arise from the carelessness, negligence or gross negligence of any
          Released Party or anyone else.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I agree to
          indemnify (reimburse for any loss) and hold harmless each Released
          Party from any loss or liability (including any reasonable legal fees
          they may incur) defending any Claim made by me or anyone making a
          Claim on my behalf, even if the Claim is alleged to or did result from
          the carelessness or negligence of any Released Party or anyone else.
        </li>
      </ol>
      <p className="p5">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I am aware that it is advisable to consult a physician and/or mental
          health professional prior to participating in the Activities. I
          represent and warrant that: (a) I have no physical or medical
          condition which would prevent me from properly and safely
          participating in the Activities; (b) I have not been instructed by a
          physician to avoid physical activity and/or exercise; (c) I have no
          symptoms or suggestions of mental distress or trauma which would
          prevent me from properly and safely participating in the Activities;
          and (d) if I have consulted a physician and/or mental health
          professional, I have taken my physician&rsquo;s advice.
        </li>
      </ol>
      <p className="p5">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I am aware that there is no obligation for any person to provide me
          with medical care during the Activities.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I
          understand and acknowledge that: (a) there may be no aid stations
          available for the Activities; (b) there will be no mental health
          professionals available for the Activities; and (c) if medical care is
          rendered to me, I consent to that care if I am unable to give my
          consent for any reason at the time the care is rendered. I acknowledge
          that I am responsible to inform a physician or mental health
          professional if I experience symptoms of mental distress or trauma
          prior, during or after my participation in the Activities.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </li>
      </ol>
      <p className="p5">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I grant my permission to the Released Party and any transferee or
          licensee or any of them, to utilize any photographs, motion pictures,
          videotapes, recordings and other references or records of the Activity
          which may depict, record or refer to me, my aural or visual likeness,
          my voice or other depictions of me or my persona or personality,
          together with my aural or visual likeness, my voice or other
          depictions of me or my persona or personality contained therein, in
          whole or in part, for any purpose (&ldquo;<strong>Images</strong>
          &rdquo;), including commercial use by the released parties, their
          sponsors and their licensees.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>This
          permission is for use anywhere in the world and on the Internet and in
          any other media now known or hereafter created and for an unlimited
          period of time.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I
          understand and agree that I will not be compensated or receive
          additional consideration for consenting to the use of the Images and
          that I will not be given a chance to receive, inspect or approve the
          promotional or marketing material, messages and/or content that may
          use the Images.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I hereby
          release the Released Party including, without limitation, all persons
          who took or otherwise created, recorded or modified the Images, from
          any and all claims, actions, damages, interest, costs, expense and
          compensation of whatsoever kind and howsoever arising, whether known
          or unknown, and which I now have or at any time hereafter can, shall
          or may have in connection with, or in any way resulting or arising
          from, the Images and the creation, use or disposition of them.
        </li>
      </ol>
      <p className="p4">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I acknowledge and agree that the Released Party shall own all right,
          title and interest, throughout the world, in and to the Images and any
          materials compromising all or any part of the Images, and that the
          Released Party has the unrestricted right to use, license, sell,
          transfer or otherwise dispose of any or all of them, as well as the
          Released Party&rsquo;s rights under this Release and Waiver, in any
          manner whatsoever and without any accountability to me. I hereby
          assign to the Released Party all right, title and interest, throughout
          the world, I may have in and to the Images and any materials
          comprising all or any part of the Images, including all intellectual
          property rights thereto. I hereby irrevocably waive any and all of my
          moral rights and any other irrevocable rights I may have, throughout
          the world, in or to the Images and any materials comprising all or
          part of the Images.
        </li>
      </ol>
      <p className="p4">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I understand that some of the information provided by me, including,
          but not limited to, my name, address, and month and day of birth (the
          &ldquo;<strong>Information</strong>&rdquo;) will be collected and used
          by the Released Party, and I consent to the transmission of the
          Information to the Released Party, its agents and/or service providers
          and authorize the Released Party, its agents and/or service providers
          to record, process and store such Information as necessary for
          purposes of providing and administering the Activity and in accordance
          with the Released Party&rsquo;s Privacy Policy, located at{" "}
          <a href="http://info.lululemon.com/legal/privacy-policy">
            <span className="s4">
              http://info.lululemon.com/legal/privacy-policy
            </span>
          </a>
        </li>
      </ol>
      <p className="p6">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I understand that I must be at least the greater of either eighteen
          (18) years of age or the age of majority in the jurisdiction where I
          reside as of the date of my participation in the Activity and, if the
          Parent/Guardian section of this Release and Waiver is not completed, I
          represent and warrant that I am either eighteen (18) years of age or
          the age of majority in the jurisdiction where I reside as of the date
          of my participation in the Activity.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>If the
          participant is less than 18 years of age or the age of majority in the
          jurisdiction where you reside, your parent or guardian agrees to the
          following statements:
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>As a parent
          or guardian of the participant child, I authorize the child to
          participate.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I agree
          that in the event the participant child, or anyone acting on his or
          her behalf, should make any claim, I will provide the indemnity and
          hold harmless described in paragraph 5 and I agree to the terms of
          this Release and Waiver.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>In the
          event of a medical emergency involving the participant child and any
          Released Party is unable to contact me, I agree and grant my
          permission that any Released Party may provide medical care to the
          participant child.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I
          acknowledge that I am responsible to inform a physician or mental
          health professional if the participant child experiences symptoms of
          mental distress prior, during or after his or her participation in the
          Activities. <span className="Apple-converted-space">&nbsp;</span>
        </li>
      </ol>
      <p className="p7">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          If any provision of this Release and Waiver shall be unlawful, void or
          for any reason unenforceable, then that provision shall be deemed
          severable from this Release and Waiver and shall not affect the
          validity and enforceability of any remaining provisions.
        </li>
      </ol>
      <p className="p8">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          I have fully read and understand this Release and Waiver.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>I am aware
          that by signing this Release and Waiver, I am waiving certain legal
          rights I or my heirs, next of kin, executors, administrators and
          assigns may have against the Released Party.
        </li>
      </ol>
      <p className="p7">
        <br />
      </p>
      <ol className="ol2">
        <li className="li3">
          If this Event is taking place in Canada, this Event Release and waiver
          will be construed in accordance with the laws of the Province of
          British Columbia.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>If this
          Event is taking place in the United States of America, this Event
          Release and waiver will be construed in accordance with the laws of
          the State of New York.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>Each party
          irrevocably submits to the exclusive jurisdiction of the state and
          federal courts located in New York with respect to resolution of
          disputes arising under this Release and Waiver.
        </li>
      </ol>
      <p className="p1">
        <br />
      </p>
    </div>
  );
};

export default Waiver;
