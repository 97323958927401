import React from 'react';

import footerLogo from "../../Media/noname_logo_2024_transparent.png"

const Footer = () => {

  return (
    <footer className='d-flex justify-content-center'>
      <img src={footerLogo} alt="noname logo" style={{width: '300px'}} />
    </footer>
  )
};

export default Footer;