import React, { useState, useEffect } from "react";

import { withFirebase } from "../Firebase";
import nonameLogo from "../../Media/noname_logo_2024_transparent_cropped.png";

const Todo = ({ firebase }) => {
  // ! Delete Code Below
  // const [joinDefine, setJoinDefine] = useState(
  //   localStorage.getItem("joinDefine") || false
  // );
  // const [postToInstaLyrics, setPostToInstaLyrics] = useState(
  //   localStorage.getItem("postToInstaLyrics") || false
  // );
  // const [saturdayRun, setSaturdayRun] = useState(
  //   localStorage.getItem("saturdayRun") || false
  // );
  // const [postToInstaVideo, setPostToInstaVideo] = useState(
  //   localStorage.getItem("postToInstaVideo") || false
  // );
  // const [completedMissions, setCompletedMissions] = useState([]);

  const [missionsSubmitted, setMissionsSubmitted] = useState(
    localStorage.getItem("missionsSubmitted_2023") || false
  );
  console.log("🚀 ~ Todo ~ missionsSubmitted_2023:", missionsSubmitted);

  const [inputValue, setInputValue] = useState("");
  const [post, setPost] = useState(false);
  console.log("🚀 ~ Todo ~ post:", post);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handlePost = () => {
    setPost((prevState) => !prevState);
  };

  let missions = {
    posted: post,
    answer: inputValue,
  };

  // ! Delete Code Below
  // const updateCompletedMissions = () => {
  //   setCompletedMissions([
  //     ...(joinDefine ? ["joinedDefine"] : []),
  //     ...(donate ? ["donated"] : []),
  //     ...(postToInstaLyrics ? ["postedHipHopLyrics"] : []),
  //     ...(saturdayRun ? ["saturdayRun"] : []),
  //     ...(postToInstaVideo ? ["postedFavoriteRun"] : []),
  //   ]);
  // };

  // const handleJoinDefine = () => {
  //   setJoinDefine(!joinDefine);
  // };

  // const handlePostToInstaLyrics = () => {
  //   setPostToInstaLyrics(!postToInstaLyrics);
  // };

  // const handleSaturdayRun = () => {
  //   setSaturdayRun(!saturdayRun);
  // };

  // const handlePostToInstaVideo = () => {
  //   setPostToInstaVideo(!postToInstaVideo);
  // };

  const onUpdateMissions = () => {
    firebase.doUpdateMissions(missions);
    setMissionsSubmitted(true);
    localStorage.setItem("missionsSubmitted_2023", true);
    // ! Delete these below
    // localStorage.setItem("donate", donate);
    // localStorage.setItem("postToInstaLyrics", postToInstaLyrics);
    // localStorage.setItem("saturdayRun", saturdayRun);
    // localStorage.setItem("postToInstaVideo", postToInstaVideo);
  };

  // ? Do I need to update this useEffect
  // useEffect(() => {}, [missionsSubmitted]);

  return (
    <div className="d-flex flex-column align-items-center text-center mx-2">
      <Modal />
      {missionsSubmitted ? (
        <div
          className="d-flex flex-column justify-content-center m-5"
          style={{ height: "60vh" }}
        >
          <h1 className="mb-3">
            {" "}
            Thank you for applying to The{" "}
            <img
              src={nonameLogo}
              alt="noname logo"
              style={{ width: "160px", paddingBottom: "5px" }}
            />{" "}
            Program: season 3 and completing your missions.{" "}
          </h1>
          <h2 className="my-3">We will finalize our team on <strong>June 11th</strong>.</h2>
          <h2>
            No matter what, the season ahead of you will be one of challenge,
            growth, and revelation. As long as you commit, the marathon will
            make sure of that.
          </h2>
          <h2>Coach + Coffey</h2>
        </div>
      ) : (
        <div>
          <h2 className="mb-3">
            The&nbsp;
            <img
              src={nonameLogo}
              alt="noname logo"
              style={{ width: "130px", paddingBottom: "5px" }}
            />
            &nbsp;Program 2024 will be limited to 350 runners.
          </h2>
          <p>
            Because space is limited, we want to be sure that all&nbsp;
            <img
              src={nonameLogo}
              alt="noname logo"
              style={{ width: "100px", paddingBottom: "5px" }}
            />
          </p>
          <p>runners are committed to their marathon journey,</p>
          <p className="mb-5">and to training as a community.</p>
          {/* <p>
            With that communal vision in mind, Coffey and Julia have chosen not
            to pick our team this year.
          </p>
          <h2 className="mb-5">You choose yourself.</h2> */}
          <h1 className="display-3">The Mission:</h1>
          <p className="mb-5">
            To join The&nbsp;
            <img
              src={nonameLogo}
              alt="noname logo"
              style={{ width: "100px", paddingBottom: "3px" }}
            />
            &nbsp;Program, complete the following 2 challenges.
          </p>
          {/* <p>
            Check off the tasks as you complete them. Once finished with all 3,
            press the "Mission Completed" button.
          </p>
          <p>
            We’ll cross-check, and you’ll hear back from us in the first week of
            July.
          </p> */}

          <div className="border rounded p-5 ">
            <div>
              Join&nbsp;
              <a
                href="https://www.instagram.com/definenewyorkrunclub/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                Define New York Run Club
              </a>
              &nbsp;for Global Running Day - June 5th.
              <a
                href="https://www.eventbrite.ca/e/global-run-day-2024-nyc-powered-by-lululemon-tickets-916716091507?aff=oddtdtcreator"
                target="_blank"
                rel="noreferrer"
              >
                <br />
                Sign up on Eventbrite
              </a>
              , and check in on site to be counted.
            </div>

            <hr style={{ border: "2px solid grey", width: "100%" }} />
            <div>
              <p className="mb-3">
                Tell us about your running journey, and why you'd like to run
                with The&nbsp;
                <img
                  src={nonameLogo}
                  alt="noname logo"
                  style={{ width: "100px", paddingBottom: "3px" }}
                />
                &nbsp;Program: season 3
              </p>
            </div>
            <h2 className="mb-3">EITHER</h2>
            <div className="mb-3">
              <input
                className="m-3"
                type="checkbox"
                id="donate"
                name="donate"
                value="donate"
                checked={post}
                onChange={handlePost}
                style={{ width: "20px", height: "20px" }}
              />
              Post an Instagram story or reel. *check box for this option <br />{" "}
              Tag @thenonameprogram, @thatcoffeyboy and @justrunjulia to be
              counted.
              <br /> **to participate in this option, your account must be
              public.
            </div>
            <h2 className="mb-3">OR</h2>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Answer below:
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="10"
                cols="70"
                maxLength="5000"
                placeholder={
                  missionsSubmitted
                    ? "Thanks for your Submission"
                    : "Tell us about your running journey, and why you'd like to run with The Noname Program: season 3?"
                }
                value={inputValue}
                onChange={handleInputChange}
              />
              <p className="text-end text-secondary fs-6">
                {inputValue.length}/5000
              </p>
            </div>
            <button
              className="btn btn-primary"
              disabled={(inputValue.length < 1 && !post) || missionsSubmitted}
              onClick={onUpdateMissions}
              data-bs-toggle="modal"
              data-bs-target="#missionCompleteModal"
            >
              {missionsSubmitted
                ? "Thanks For Your Submission"
                : "Mission Complete"}
            </button>
          </div>
        </div>
      )}

      {/*//! Delete the old mission code below */}
      {/* <div className="border rounded p-5 text-start">
        <div>
          <input
            className="m-3"
            type="checkbox"
            id="joinDefine"
            name="joinDefine"
            value="joinDefine"
            checked={joinDefine}
            onChange={handleJoinDefine}
          />
          Join&nbsp;
          <a
            href="https://www.instagram.com/definenewyorkrunclub/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            Define New York Run Club
          </a>
          &nbsp;for Global Running Day - June 7th.
          <a
            href="https://www.eventbrite.com/e/global-running-day-tickets-644579934937"
            target="_blank"
            rel="noreferrer"
          >
            <br />
            Sign up on Eventbrite
          </a>
          , and check in on site to be counted.
        </div>

        <hr style={{ border: "2px solid grey", width: "100%" }} />

        <div>
          <input
            className="m-3"
            type="checkbox"
            id="donate"
            name="donate"
            value="donate"
            checked={donate}
            onChange={handleDonate}
          />
          Donate to support&nbsp;
          <a
            href="https://artists-athletes-activists.org/"
            target="_blank"
            rel="noreferrer"
          >
            Artists Activists Athletes
          </a>
          &nbsp;in their work to serve marginalized and underserved communities.
          <br /> Send a donation confirmation screenshot to&nbsp;
          <a href="mailto:hello@thenonameprogram.com">
            hello@thenonameprogram.com
          </a>
          &nbsp;with subject line: LAST NAME, FIRST NAME.
        </div>

        <hr style={{ border: "2px solid grey", width: "100%" }} />

        <div>
          <input
            className="m-3"
            type="checkbox"
            id="postToInstaLyrics"
            name="postToInstaLyrics"
            value="postToInstaLyrics"
            checked={postToInstaLyrics}
            onChange={handlePostToInstaLyrics}
          />
          Post an Instagram story or reel sharing hip hop lyrics that inspire
          you to get out the door, take a leap of faith, and bet on yourself.
          <br />
          Tag <strong>@thenonameprogram</strong>,<strong>@thatcoffeyboy</strong>
          and <strong>@justrunjulia</strong> to be counted. **to participate in
          this option, your account must be public.
        </div>

        <hr style={{ border: "2px solid grey", width: "100%" }} />

        <div>
          <input
            className="m-3"
            type="checkbox"
            id="saturdayRun"
            name="saturdayRun"
            value="saturdayRun"
            checked={saturdayRun}
            onChange={handleSaturdayRun}
          />
          Join&nbsp;
          <a
            href="https://www.instagram.com/definenewyorkrunclub/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            Define New York Run Club
          </a>
          &nbsp;for a Saturday Long Run on June 3, 10, 17, or 24 at 8:00am @
          &nbsp;
          <a
            href="https://www.google.com/maps/place/Dick+and+Jane%E2%80%99s/@40.689854,-74.037275,13z/data=!4m7!3m6!1s0x89c25bb757ff2f4f:0xfdf2798bd5e070f9!8m2!3d40.689854!4d-73.971357!15sChJkaWNrIGFuZCBqYW5lcyBiYXJaFCISZGljayBhbmQgamFuZXMgYmFykgEDYmFy4AEA!16s%2Fg%2F11cfhrxxx?hl=en-us&entry=tts&shorturl=1"
            target="_blank"
            rel="noreferrer"
          >
            Dick and Jane’s
          </a>
          <br />
          Check in with&nbsp;
          <a
            href="https://www.instagram.com/cwm1113/"
            target="_blank"
            rel="noreferrer"
          >
            Cheryl
          </a>
          &nbsp;on site to be counted. If you don't know her yet, ask around!
        </div>

        <hr style={{ border: "2px solid grey", width: "100%" }} />

        <div>
          <input
            className="m-3"
            type="checkbox"
            id="postToInstaVideo"
            name="postToInstaVideo"
            value="postToInstaVideo"
            checked={postToInstaVideo}
            onChange={handlePostToInstaVideo}
          />
          Post an Instagram story or reel of your favorite run. Show us the
          spots and tell us what this run, or running in general, means to yous.
          <br />
          Tag <strong>@thenonameprogram</strong>,<strong>@thatcoffeyboy</strong>
          and <strong>@justrunjulia</strong> to be counted. **to participate in
          this option, your account must be public.
        </div>

        <hr style={{ border: "2px solid grey", width: "100%" }} />
        <div className="text-center">
          <button
            className="btn btn-primary"
            disabled={completedMissions.length < 3 || missionsSubmitted}
            onClick={onUpdateMissions}
            data-bs-toggle="modal"
            data-bs-target="#missionCompleteModal"
          >
            {missionsSubmitted
              ? "Thanks For Your Submission"
              : "Mission Complete"}
          </button>
        </div>
      </div> */}
    </div>
  );
};

const Modal = () => {
  return (
    <div
      className="modal fade"
      id="missionCompleteModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Missions Submitted
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">Thanks for your participation.</div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Todo);
