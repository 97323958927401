const workoutData = [
  {
    week: "WEEK 1",
    body: [
      {
        date: "7/18/2023",
        type: "Speed Work",
        start: `Start @ Central Park Mall @ The n̶o̶name Rock`,
        finish: "End @ Marcus Garvey Park",
        description: "10x400 in-and-outs",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "7/20/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Runner Safety",
        timeframe: "Session @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "7/22/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "7 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 2",
    body: [
      {
        date: "7/25/2023",
        type: "Speed Work",
        start: "74th st. Track",
        finish: null,
        description: "5 miles at Marathon goal pace",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "7/27/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "7/29/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "9 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 3",
    body: [
      {
        date: "8/1/2023",
        type: "Speed Work",
        start: "Strawberry Hill",
        finish: null,
        description: "Tony's Hills",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/3/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/5/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "10 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 4",
    body: [
      {
        date: "8/8/2023",
        type: "Speed Work",
        start: "Engineer's Gate",
        finish: null,
        description: "Speedplay Pyramid",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/10/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Nutrition",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/12/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "9 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 5",
    body: [
      {
        date: "8/15/2023",
        type: "Speed Work",
        start: "Pier 64 @ Hudson River Park",
        finish: null,
        description: "30 minute tempo",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/17/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/19/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "12 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 6",
    body: [
      {
        date: "8/22/2023",
        type: "Speed Work",
        start: "East River Park Track ",
        finish: null,
        description: "12-16 x 400 meters",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/24/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/26/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "Half marathon",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 7",
    body: [
      {
        date: "8/29/2023",
        type: "Speed Work",
        start: "The n̶o̶name Rock",
        finish: null,
        description: "Alternate 800s – 48 minutes",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "8/31/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Fashion Show",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/2/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "1:30 Timed Run",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 8",
    body: [
      {
        date: "9/5/2023",
        type: "Speed Work",
        start: "Harlem Hill, West Side",
        finish: null,
        description: "Tony's Hills",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/7/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/9/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "15 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 9",
    body: [
      {
        date: "9/12/2023",
        type: "Speed Work",
        start: "Pier 76 @ Hudson River Park",
        finish: null,
        description: "30 minute pace injector tempo",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/14/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/16/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "2:00 Timed Run",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 10",
    body: [
      {
        date: "9/19/2023",
        type: "Speed Work",
        start: "HQ",
        finish: null,
        description: "4x1 Bridge Mile",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/21/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/23/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "1:30 Timed Run",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 11",
    body: [
      {
        date: "9/26/2023",
        type: "Speed Work",
        start: "Thomas Jefferson Park Track",
        finish: null,
        description: "8x4:00 epicycles",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/28/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "9/30/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "18 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 12",
    body: [
      {
        date: "10/3/2023",
        type: "Speed Work",
        start: "Tony's Hill",
        finish: null,
        description: "Tony's Hills",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "10/5/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "One Month Out",
        timeframe: "7:00, doors at 6:30",
        isMandatory: true,
      },
      {
        date: "10/7/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "1:30 Timed Run",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 13",
    body: [
      {
        date: "10/10/2023",
        type: "Speed Work",
        start: "The n̶o̶name Rock",
        finish: null,
        description: "Alternate 800s – 60 minutes",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "10/12/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "10/14/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "20 miles",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 14",
    body: [
      {
        date: "10/17/2023",
        type: "Speed Work",
        start: "TBD",
        finish: null,
        description: "TBD",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "10/19/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "10/21/2023",
        type: "The Long Run",
        start: "HQ",
        finish: null,
        description: "Half marathon",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 15",
    body: [
      {
        date: "10/24/2023",
        type: "Speed Work",
        start: "Strawberry Hill",
        finish: null,
        description: "30 Minute tempo",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "10/26/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Choices in Strength",
        timeframe: "Workout @ 7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "10/28/2023",
        type: "The Long Run",
        start: "59th & 1st",
        finish: null,
        description: "The Last Ten",
        timeframe: "Run @ 8am, doors @ 7:30am",
        isMandatory: true,
      },
    ],
  },
  {
    week: "WEEK 16",
    body: [
      {
        date: "10/31/2023",
        type: "Speed Work",
        start: "Engineer's Gate",
        finish: null,
        description: "3 Miles @ Marathon Place",
        timeframe: "Workout @ 7:00pm, bag check @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "11/2/2023",
        type: "The Workshop",
        start: "HQ",
        finish: null,
        description: "Final Words with Coach & Coffey",
        timeframe: "Session @7-8:30pm, doors @ 6:30pm",
        isMandatory: true,
      },
      {
        date: "11/4/2023",
        type: "The Shakeout",
        start: "HQ",
        finish: null,
        description: "3 Miles",
        timeframe: "10am",
        isMandatory: true,
      },
      {
        date: "11/5/2023",
        type: "The marathon",
        start: null,
        finish: null,
        description: null,
        timeframe: null,
        isMandatory: true,
      },
    ],
  },
];

export default workoutData;
