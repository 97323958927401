import React, { useEffect } from "react";

import { PasswordForgetForm } from "../PasswordForget";
import PasswordChangeForm from "../PasswordChange";
import UpdateUser from "../UpdateUser";
import { AuthUserContext, withAuthorization } from "../Session";

const AccountPage = () => {
  useEffect(() => {
    console.log("test");
  }, []);
  return (
    <div>
      <h1 className="text-center">Your Information</h1>
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container d-flex flex-column align-items-center my-5">
            <div className="container d-flex flex-column align-items-start px-sm-1 px-md-5" >
              <p>
                <strong>Name: </strong> {authUser.username}
              </p>
              <p>
                <strong>Nickname: </strong> {authUser.nickname}
              </p>
              {/* <p>
            <strong>DOB: </strong> {authUser.DOB}
          </p> */}
              <p>
                <strong>Email: </strong> {authUser.email}
              </p>
              <p>
                <strong>Pronouns: </strong> {authUser.pronouns}
              </p>
              <p>
                <strong>Phone: </strong> {authUser.phone}
              </p>
              {/* <p>
            <strong>Emergency Contact: </strong>
            {authUser.emergencyContact}
          </p>
          <p>
            <strong>Emergency Phone: </strong>
            {authUser.emergencyPhone}
          </p> */}
              <p>
                <strong>Instagram Handle: </strong>
                {authUser.instaHandle}
              </p>
              <p>
                <strong>Accessability Needs: </strong>
                {authUser.accessabilityNeeds}
              </p>
              {/* <p>
            <strong>Your Running Crew: </strong>
            {authUser.runningCrew}
          </p> */}
              <p>
                <strong>Previous marathons: </strong>
                {authUser.previousMarathons}
              </p>
              <p>
                <strong>Current marathon(s): </strong>
                {authUser.trainingMarathon === "other"
                  ? authUser.trainingOtherMarathon
                  : authUser.trainingMarathon === "multiple"
                  ? authUser.trainingMultipleMarathon
                  : authUser.trainingMarathon}
              </p>
              {/* <p>
            <strong>I've worn lululemon: </strong>
            {authUser.wornLululemonBefore}
          </p>
          <p>
            <strong>Top Size: </strong>
            {authUser.topSize}
          </p>
          <p>
            <strong>Bra Size: </strong>
            {authUser.braSize !== "" ? authUser.braSize : "n/a"}
          </p>
          <p>
            <strong>Short Size: </strong>
            {authUser.shortSize}
          </p> */}
              {/* //! The two mission codes below don't work */}
              {/* <p>
            <strong>Did I post to Insta as my mission: </strong>
            {(authUser?.missions?.posted ? 'I did post' : 'Did not post') || 'Please Complete Missions'}

          </p>
          <p>
            <strong>What does running mean to you?: </strong>
            {authUser.missions.answer || 'Please Complete Missions'}
          </p> */}
              <p>
                <strong>Missions Complete: </strong>
                {authUser.missions
                  ? "Missions Complete"
                  : "Please Complete Your Missions"}
              </p>
              <p>
                <strong>Status: </strong>
                {authUser.isApproved ? "Approved" : "Not Approved"}
              </p>
            </div>
            {/* <PasswordForgetForm /> */}
            <div className="border rounded p-5 mb-5">
              <h2 className="my-5">
                If Needed, please use this form to update your password:
              </h2>
              <PasswordChangeForm />
            </div>
            <div className="border rounded p-5">
              <h2 className="my-5">
                If needed, please use this form to update your User Info:
              </h2>
              <UpdateUser authUser={authUser} />
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AccountPage);
