import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import { withFirebase } from "../Firebase";
// import { AuthUserContext } from "../Session";
import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import Todo from "../ToDo";
import Footer from "../Footer";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from '../Session';


const App = () => (
  <Router>
    <div>
      <Navigation />

      {/* <hr /> */}
      <div style={{marginTop: '90px'}}>
        <Switch>
          <Route exact path={ROUTES.LANDING}>
            <LandingPage />
          </Route>
          <Route path={ROUTES.SIGN_UP}>
            <SignUpPage />
          </Route>
          <Route path={ROUTES.SIGN_IN}>
            <SignInPage />
          </Route>
          <Route path={ROUTES.PASSWORD_FORGET}>
            <PasswordForgetPage />
          </Route>
          <Route path={ROUTES.HOME}>
            <HomePage />
          </Route>
          <Route path={ROUTES.TODO}>
            <Todo />
          </Route>
          <Route path={ROUTES.ACCOUNT}>
            <AccountPage />
          </Route>
          <Route path={ROUTES.ADMIN}>
            <AdminPage />
          </Route>
        </Switch>
      </div>
      <Footer />
    </div>
  </Router>
);


export default withAuthentication(App);
