import React, { Component } from 'react'; 
import { Link, withRouter } from 'react-router-dom';
import emailjs from "@emailjs/browser";


import { withFirebase } from "../Firebase";
import * as ROUTES from '../../constants/routes';
import * as ROLES from "../../constants/roles";
import nonameLogo from '../../Media/noname_Logo_4C.png';
import Waiver from './waver';


const SignUpPage = () => {
  
  let today = new Date().toLocaleDateString();
  let changeDate = new Date("06-30-2023").toLocaleDateString()

  function isLater() {
    return today > changeDate;
  }

  return (
    <div className="container d-flex flex-column align-items-center my-5">
      <h1 className="mb-5 display-4">
         Application for 2024:
      </h1>
      <h3>The marathon requires everything you’ve got—</h3>
      <h3>All your strength. All your experience. All your soft spots.</h3>
      <h3>Come as you are, and remember:</h3>
      <h3>The cracks are where the light gets in.</h3>
      <h3 className="mb-5">
        As a&nbsp;
        <img src={nonameLogo} alt="noname logo" style={{ width: "90px" }} />
        &nbsp;runner, we want you to show up as your whole self, every time.
      </h3>
      <SignUpForm />
    </div>
  );
};

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  nickname: "",
  pronouns: "",
  phone: "",
  // emergencyContact: "",
  // emergencyPhone: "",
  // DOB:"",
  isAdmin: false,
  isApproved: false,
  missions: [],
  instaHandle: "",
  accessabilityNeeds: "",
  // runningCrew: "",
  previousMarathons: "",
  trainingMarathon: "",
  trainingOtherMarathon: "",
  trainingMultipleMarathon: "",
  // wornLululemonBefore: "",
  // topSize: "",
  // braSize: "",
  // shortSize: "",
  agreement1: false,
  agreement2: false,
  signUpDate: new Date().toLocaleDateString(),
  error: null,
};

class SignUpFormBase extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }


  sendEmail = () => {
    var templateParams = {
      email: this.state.email
    }

    emailjs
      .send(
        "service_vce0hjm",
        "template_jl4b6fv",
        templateParams,
        "pU5_UUjnAcJyStSxT"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  onSubmit = (event) => {
    const {
      username,
      email,
      passwordOne,
      nickname,
      pronouns,
      phone,
      // emergencyContact,
      // emergencyPhone,
      // DOB,
      isAdmin,
      missions,
      instaHandle,
      accessabilityNeeds,
      // runningCrew,
      previousMarathons,
      trainingMarathon,
      trainingOtherMarathon,
      trainingMultipleMarathon,
      // wornLululemonBefore,
      // topSize,
      // braSize,
      // shortSize,
      signUpDate,
      isApproved,
    } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          nickname,
          pronouns,
          phone,
          // emergencyContact,
          // emergencyPhone,
          isApproved,
          // DOB,
          missions,
          instaHandle,
          accessabilityNeeds,
          // runningCrew,
          previousMarathons,
          trainingMarathon,
          trainingOtherMarathon,
          trainingMultipleMarathon,
          // wornLululemonBefore,
          // topSize,
          // braSize,
          // shortSize,
          signUpDate,
        });
      })
      .then(() => {
        this.sendEmail();
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.TODO);
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAgreement1 = () => {
    this.setState({ agreement1: !this.state.agreement1 });
  };
  handleAgreement2 = () => {
    this.setState({ agreement2: !this.state.agreement2 });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      nickname,
      pronouns,
      phone,
      // emergencyContact,
      // emergencyPhone,
      // DOB,
      instaHandle,
      accessabilityNeeds,
      // runningCrew,
      previousMarathons,
      trainingMarathon,
      trainingOtherMarathon,
      trainingMultipleMarathon,
      // wornLululemonBefore,
      // topSize,
      // braSize,
      // shortSize,
      agreement1,
      agreement2,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "" ||
      phone === "" ||
      // emergencyContact === "" ||
      // emergencyPhone === "" ||
      instaHandle === "" ||
      accessabilityNeeds === "" ||
      // runningCrew === "" ||
      previousMarathons === "" ||
      trainingMarathon === "" ||
      // wornLululemonBefore === "" ||
      // topSize === "" ||
      // shortSize === "" ||
      agreement1 === false ||
      agreement2 === false;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="mb-3">
          <p style={{ color: "red", fontSize: "16px" }}>
            **all fields are required unless labeled optional
          </p>
          <label htmlFor="inputName" className="form-label">
            First and Last Name
          </label>
          <input
            className="form-control"
            id="inputName"
            name="username"
            value={username}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputNickname" className="form-label">
            Nickname (optional)
          </label>
          <input
            className="form-control"
            id="inputNickname"
            name="nickname"
            value={nickname}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputPronouns" className="form-label">
            Pronouns (optional)
          </label>
          <input
            className="form-control"
            id="inputPronouns"
            name="pronouns"
            value={pronouns}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputPhone" className="form-label">
            Phone
          </label>
          <input
            className="form-control"
            id="inputPhone"
            name="phone"
            value={phone}
            onChange={this.onChange}
            type="text"
          />
        </div>
        {/* <div className="mb-3">
          <label htmlFor="inputEmergencyContact" className="form-label">
            Emergency Contact
          </label>
          <input
            className="form-control"
            id="inputEmergencyContact"
            name="emergencyContact"
            value={emergencyContact}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputEmergencyPhone" className="form-label">
            Emergency phone
          </label>
          <input
            className="form-control"
            id="inputEmergencyPhone"
            name="emergencyPhone"
            value={emergencyPhone}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputDOB" className="form-label">
            Month and Day of Birth **No Year (month/day) (optional)
          </label>
          <input
            className="form-control"
            id="inputDOB"
            name="DOB"
            value={DOB}
            onChange={this.onChange}
            type="text"
          />
        </div> */}
        <div className="mb-3">
          <label htmlFor="inputEmail" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="inputEmail"
            aria-describedby="emailHelp"
            name="email"
            value={email}
            onChange={this.onChange}
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="inputPassword" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="inputPassword"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputPasswordConfirmation" className="form-label">
            Confirm Password
          </label>
          <input
            className="form-control"
            id="inputPasswordConfirmation"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputInstaHandle" className="form-label">
            Instagram Handle
          </label>
          <input
            className="form-control"
            name="instaHandle"
            value={instaHandle}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputAccessibilityNeeds" className="form-label">
            Do you have any accessibility needs we can accommodate?
          </label>
          <textarea
            className="form-control"
            name="accessabilityNeeds"
            id="inputAccessibilityNeeds"
            cols="30"
            rows="3"
            placeholder="If yes, please specify your requests in 1-2 sentences."
            value={accessabilityNeeds}
            onChange={this.onChange}
          ></textarea>
        </div>
        {/* <div className="mb-3">
          <label htmlFor="inputRunningCrews" className="form-label">
            Do you run with a crew?
          </label>
          <textarea
            className="form-control"
            name="runningCrew"
            id="inputRunningCrews"
            cols="30"
            rows="3"
            placeholder="If yes, which club or crew?"
            value={runningCrew}
            onChange={this.onChange}
          ></textarea>
        </div> */}
        <div className="mb-3">
          <label htmlFor="inputPreviousMarathons" className="form-label">
            Is this your first Marathon?
          </label>
          <textarea
            className="form-control"
            name="previousMarathons"
            id="inputPreviousMarathons"
            cols="30"
            rows="3"
            placeholder="If “No” how many Marathons have you run previously? Include which Marathon, year completed, and finishing time."
            value={previousMarathons}
            onChange={this.onChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="inputTrainingMarathon" className="form-label">
            Choose the marathon that you'll be running this year.
          </label>
          <select
            className="form-select mb-1"
            name="trainingMarathon"
            id="inputTrainingMarathon"
            defaultValue="default"
            onChange={this.onChange}
          >
            <option value="default">Choose one...</option>
            <option value="New York City / November 3, 2024">
              New York City / November 3, 2024
            </option>
            <option value="Chicago / October 13, 2024">
              Chicago / October 13, 2024
            </option>
            <option value="Berlin / September 29, 2024">
              Berlin / September 29, 2024
            </option>
            <option value="multiple">I'm running multiple marathons...</option>
            <option value="other">Other...</option>
          </select>
          {this.state.trainingMarathon === "other" ? (
            <textarea
              className="form-control"
              name="trainingOtherMarathon"
              id="inputOtherTrainingMarathon"
              cols="30"
              rows="3"
              placeholder="Please tell us what marathon you will be running."
              value={trainingOtherMarathon}
              onChange={this.onChange}
            ></textarea>
          ) : (
            ""
          )}
          {this.state.trainingMarathon === "multiple" ? (
            <textarea
              className="form-control"
              name="trainingMultipleMarathon"
              id="inputTrainingMultipleMarathon"
              cols="30"
              rows="3"
              placeholder="Please tell us all the marathons you will be running."
              value={trainingMultipleMarathon}
              onChange={this.onChange}
            ></textarea>
          ) : (
            ""
          )}
        </div>
        {/* <div className="mb-3">
          <label htmlFor="inputWornLululemonBefore" className="form-label">
            Have you worn lululemon before?
          </label>
          <select
            className="form-select"
            name="wornLululemonBefore"
            id="inputWornLululemonBefore"
            defaultValue="default"
            onChange={this.onChange}
          >
            <option value="default">Choose one...</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="inputTopSize" className="form-label">
            Please choose your top size?
          </label>
          <select
            className="form-select"
            name="topSize"
            id="inputTopSize"
            defaultValue="default"
            onChange={this.onChange}
          >
            <option value="default">Choose one...</option>
            <option value="Men's - XS">Men's - XS</option>
            <option value="Men's - S">Men's - S</option>
            <option value="Men's - M">Men's - M</option>
            <option value="Men's - L">Men's - L</option>
            <option value="Men's - XL">Men's - XL</option>
            <option value="Men's - XXL">Men's - XXL</option>
            <option value="Women's - 2">Women's - 2</option>
            <option value="Women's - 4">Women's - 4</option>
            <option value="Women's - 6">Women's - 6</option>
            <option value="Women's - 8">Women's - 8</option>
            <option value="Women's - 10">Women's - 10</option>
            <option value="Women's - 12">Women's - 12</option>
            <option value="Women's - 14">Women's - 14</option>
            <option value="Women's - 16">Women's - 16</option>
            <option value="Women's - 18">Women's - 18</option>
            <option value="Women's - 20">Women's - 20</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="inputBraSize" className="form-label">
            Please choose your bra size? (optional)
          </label>
          <select
            className="form-select"
            name="braSize"
            id="inputBraSize"
            defaultValue="default"
            onChange={this.onChange}
          >
            <option value="default">Choose one...</option>
            <option value="Women's - 2">Women's - 2</option>
            <option value="Women's - 4">Women's - 4</option>
            <option value="Women's - 6">Women's - 6</option>
            <option value="Women's - 8">Women's - 8</option>
            <option value="Women's - 10">Women's - 10</option>
            <option value="Women's - 12">Women's - 12</option>
            <option value="Women's - 14">Women's - 14</option>
            <option value="Women's - 16">Women's - 16</option>
            <option value="Women's - 18">Women's - 18</option>
            <option value="Women's - 20">Women's - 20</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="inputShortSize" className="form-label">
            Please choose your short size?
          </label>
          <select
            className="form-select"
            name="shortSize"
            id="inputShortSize"
            defaultValue="default"
            onChange={this.onChange}
          >
            <option value="default">Choose one...</option>
            <option value="Men's - XS">Men's - XS</option>
            <option value="Men's - S">Men's - S</option>
            <option value="Men's - M">Men's - M</option>
            <option value="Men's - L">Men's - L</option>
            <option value="Men's - XL">Men's - XL</option>
            <option value="Men's - XXL">Men's - XXL</option>
            <option value="Women's - 2">Women's - 2</option>
            <option value="Women's - 4">Women's - 4</option>
            <option value="Women's - 6">Women's - 6</option>
            <option value="Women's - 8">Women's - 8</option>
            <option value="Women's - 10">Women's - 10</option>
            <option value="Women's - 12">Women's - 12</option>
            <option value="Women's - 14">Women's - 14</option>
            <option value="Women's - 16">Women's - 16</option>
            <option value="Women's - 18">Women's - 18</option>
            <option value="Women's - 20">Women's - 20</option>
          </select>
        </div> */}
        <div className="mb-3">
          <div
            className="border rounded p-4"
            style={{ height: "400px", overflowY: "scroll" }}
          >
            <Waiver />
          </div>
          <div className="my-3 form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="agreement1"
              value="agreement1"
              name="agreement1"
              checked={agreement1}
              onChange={this.handleAgreement1}
            />
            <label
              className="form-check-label"
              htmlFor="agreement1"
              style={{ fontSize: "16px" }}
            >
              I certify that I am at least the greater of either eighteen (18)
              years of age or the age of majority in the jurisdiction where I
              reside as of the date of my participation in the Activity
              otherwise my parent/guardian will sign a waiver on my behalf
              before I participate in the Activity.
            </label>
          </div>
          <div className="my-3 form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="agreement2"
              value="agreement2"
              name="agreement2"
              checked={agreement2}
              onChange={this.handleAgreement2}
            />
            <label
              className="form-check-label"
              htmlFor="agreement2"
              style={{ fontSize: "16px" }}
            >
              I agree to the terms above.
            </label>
          </div>
        </div>

        <button disabled={isInvalid} type="submit" className="btn btn-primary">
          Submit
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
    
const SignUpLink = () => ( <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;
export { SignUpForm, SignUpLink };