import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from "../../constants/roles";

import { AuthUserContext } from '../Session';
import logo from '../../Media/lululemon_Yogo_RGB_CLR.png';
import noname from '../../Media/noname_logo_2024_transparent.png';


const Navigation = () => (
  
  <div>
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  </div>
);

// const NavigationAuth = ({ authUser }) => (
//   <ul>
//     <li>
//       <Link to={ROUTES.LANDING}>Landing</Link>
//     </li>
//     <li>
//       <Link to={ROUTES.HOME}>Home</Link>
//     </li>
//     <li>
//       <Link to={ROUTES.ACCOUNT}>Account</Link>
//     </li>
    // {!!authUser.roles[ROLES.ADMIN] && (
    //   <li>
    //     <Link to={ROUTES.ADMIN}>Admin</Link>
    //   </li>
    // )}
//     <li>
//       <SignOutButton />
//     </li>
//   </ul>
// );

const NavigationAuth = ({ authUser }) => {
  const approvedUser = { authUser }.authUser.isApproved;
  const [colorChange, setColorchange] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(prevNav => !prevNav);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const updateColor = colorChange ? "bg-light" : "";
  
  return (
    <nav className={`navbar navbar-expand-lg sticky-top navbar-light bg-light`}>
      <div className="container-fluid">
        <div className="navbar-brand">
          <img src={logo} alt="lululemon logo" style={{ width: "30px" }} />
          <img
            src={noname}
            alt="noname logo"
            style={{ width: "200px",height: 'auto', marginLeft: "10px" }}
          />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarNavAltMarkup"
        >
          <div
            className="navbar-nav d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="d-flex flex-column flex-lg-row">
              <Link className="nav-link" to={ROUTES.LANDING}>
                Welcome
              </Link>
              {!approvedUser && (
                <Link className="nav-link" to={ROUTES.TODO}>
                  TheMission
                </Link>
              )}
              {approvedUser && (
                <Link className="nav-link" to={ROUTES.HOME}>
                  Program
                </Link>
              )}
              <Link className="nav-link" to={ROUTES.ACCOUNT}>
                Account
              </Link>
              {!!authUser.roles[ROLES.ADMIN] && (
                <Link className="nav-link" to={ROUTES.ADMIN}>
                  Admin
                </Link>
              )}
            </div>
            <div>
              <SignOutButton />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

// const NavigationNonAuth = () => (
//   <ul>
//     <li>
//       <Link to={ROUTES.LANDING}>Landing</Link>
//     </li>
//     <li>
//       <Link to={ROUTES.SIGN_IN}>Sign In</Link>
//     </li>
//   </ul>
// );

const NavigationNonAuth = () => {

  const [colorChange, setColorchange] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(prevNav => !prevNav);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  const updateColor = colorChange ? "bg-light" : "";
  
  return (
    <nav className={`navbar navbar-expand-lg sticky-top navbar-light bg-light`}>
      <div className="container-fluid">
        <div className="navbar-brand">
          <img src={logo} alt="lululemon logo" style={{ width: "30px" }} />
          <img
            src={noname}
            alt="noname logo"
            style={{ width: "200px",height: 'auto', marginLeft: "10px" }}
          />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <Link className="nav-link" to={ROUTES.LANDING}>
              Welcome
            </Link>
            <Link className="nav-link" to={ROUTES.SIGN_IN}>
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;