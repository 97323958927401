import React, { Component } from 'react'

import { withFirebase } from "../Firebase";

export class UpdateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.authUser.username,
      nickname: this.props.authUser.nickname,
      DOB: this.props.authUser.DOB,
      email: this.props.authUser.email,
      pronouns: this.props.authUser.pronouns,
      phone: this.props.authUser.phone,
      emergencyContact: this.props.authUser.emergencyContact,
      emergencyPhone: this.props.authUser.emergencyPhone,
      instaHandle: this.props.authUser.instaHandle,
      accessabilityNeeds: this.props.authUser.accessabilityNeeds,
      runningCrew: this.props.authUser.runningCrew,
      previousMarathons: this.props.authUser.previousMarathons,
      trainingMarathon: this.props.authUser.trainingMarathon,
      trainingOtherMarathon: this.props.authUser.trainingOtherMarathon,
      trainingMultipleMarathon: this.props.authUser.trainingMultipleMarathon,
      topSize: this.props.authUser.topSize,
      braSize: this.props.authUser.braSize,
      shortSize: this.props.authUser.shortSize,
      wornLululemonBefore: this.props.authUser.wornLululemonBefore,
      error: null,
    };
  }

  onSubmit = async (event) => {
    const {
      username,
      nickname,
      // email,
      // DOB,
      pronouns,
      phone,
      // emergencyContact,
      // emergencyPhone,
      instaHandle,
      accessabilityNeeds,
      // runningCrew,
      previousMarathons,
      trainingMarathon,
      trainingOtherMarathon,
      trainingMultipleMarathon,
      // topSize,
      // braSize,
      // shortSize,
      // wornLululemonBefore,
    } = this.state;

    var updates = {
      username,
      nickname,
      // DOB,
      pronouns,
      phone,
      // emergencyContact,
      // emergencyPhone,
      instaHandle,
      accessabilityNeeds,
      // runningCrew,
      previousMarathons,
      trainingMarathon,
      trainingOtherMarathon,
      trainingMultipleMarathon,
      // topSize,
      // braSize,
      // shortSize,
      // wornLululemonBefore,
    };

    try {
      await this.props.firebase.doUpdateUserInfo(updates);
      // alert("user info updated");
      // window.location.reload();
    } catch (error) {
      this.setState({ error });
    }
    event.preventDefault();
  };



  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      username,
      error,
      nickname,
      // DOB,
      pronouns,
      phone,
      // emergencyContact,
      // emergencyPhone,
      instaHandle,
      accessabilityNeeds,
      // runningCrew,
      previousMarathons,
      trainingMarathon,
      trainingOtherMarathon,
      trainingMultipleMarathon,
      // topSize,
      // braSize,
      // shortSize,
      // wornLululemonBefore,
    } = this.state;

    return (
      <div>
        <Modal />
        <form onSubmit={this.onSubmit}>
          <div className="d-flex flex-column my-5">
            <div className="mb-3">
              <label htmlFor="inputName" className="form-label">
                Full Name
              </label>
              <input
                name="username"
                id="inputName"
                value={username}
                onChange={this.onChange}
                type="text"
                placeholder="Full Name"
                className="form-control mb-3"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputNickname" className="form-label">
                Nickname (optional)
              </label>
              <input
                className="form-control"
                id="inputNickname"
                name="nickname"
                value={nickname}
                onChange={this.onChange}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputPronouns" className="form-label">
                Pronouns (optional)
              </label>
              <input
                className="form-control"
                id="inputPronouns"
                name="pronouns"
                value={pronouns}
                onChange={this.onChange}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputPhone" className="form-label">
                Phone
              </label>
              <input
                className="form-control"
                id="inputPhone"
                name="phone"
                value={phone}
                onChange={this.onChange}
                type="text"
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="inputEmergencyContact" className="form-label">
                Emergency Contact
              </label>
              <input
                className="form-control"
                id="inputEmergencyContact"
                name="emergencyContact"
                value={emergencyContact}
                onChange={this.onChange}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmergencyPhone" className="form-label">
                Emergency phone
              </label>
              <input
                className="form-control"
                id="inputEmergencyPhone"
                name="emergencyPhone"
                value={emergencyPhone}
                onChange={this.onChange}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputDOB" className="form-label">
                Month and Day of Birth **No Year (month/day) (optional)
              </label>
              <input
                className="form-control"
                id="inputDOB"
                name="DOB"
                value={DOB}
                onChange={this.onChange}
                type="text"
              />
            </div> */}
            {/* <div className="mb-3">
              <label htmlFor="inputEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                aria-describedby="emailHelp"
                name="email"
                value={email}
                onChange={this.onChange}
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div> */}
            <div className="mb-3">
              <label htmlFor="inputInstaHandle" className="form-label">
                Instagram Handle
              </label>
              <input
                className="form-control"
                name="instaHandle"
                value={instaHandle}
                onChange={this.onChange}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputAccessibilityNeeds" className="form-label">
                Do you have any accessibility needs we can accommodate?
              </label>
              <textarea
                className="form-control"
                name="accessabilityNeeds"
                id="inputAccessibilityNeeds"
                cols="30"
                rows="3"
                placeholder="If yes, please specify your requests in 1-2 sentences."
                value={accessabilityNeeds}
                onChange={this.onChange}
              ></textarea>
            </div>
            {/* <div className="mb-3">
              <label htmlFor="inputRunningCrews" className="form-label">
                Do you run with a crew?
              </label>
              <textarea
                className="form-control"
                name="runningCrew"
                id="inputRunningCrews"
                cols="30"
                rows="3"
                placeholder="If yes, which club or crew?"
                value={runningCrew}
                onChange={this.onChange}
              ></textarea>
            </div> */}
            <div className="mb-3">
              <label htmlFor="inputPreviousMarathons" className="form-label">
                Is this your first Marathon?
              </label>
              <textarea
                className="form-control"
                name="previousMarathons"
                id="inputPreviousMarathons"
                cols="30"
                rows="3"
                placeholder="If “No” how many Marathons have you run previously? Include which Marathon, year completed, and finishing time."
                value={previousMarathons}
                onChange={this.onChange}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="inputTrainingMarathon" className="form-label">
                Choose the marathon that you'll be running this year.
              </label>
              <select
                className="form-select mb-1"
                name="trainingMarathon"
                id="inputTrainingMarathon"
                value={trainingMarathon}
                onChange={this.onChange}
              >
                <option value="default">Choose one...</option>
                <option value="New York City / November 5, 2023">
                  New York City / November 3, 2024
                </option>
                <option value="Chicago / October 8, 2023">
                  Chicago / October 13, 2024
                </option>
                <option value="Berlin / September 24, 2023">
                  Berlin / September 29, 2024
                </option>
                <option value="multiple">
                  I'm running multiple marathons...
                </option>
                <option value="other">Other...</option>
              </select>
              {trainingMarathon === "other" ? (
                <textarea
                  className="form-control"
                  name="trainingOtherMarathon"
                  id="inputOtherTrainingMarathon"
                  cols="30"
                  rows="3"
                  placeholder="Please tell us what marathon you will be running."
                  value={trainingOtherMarathon}
                  onChange={this.onChange}
                ></textarea>
              ) : (
                ""
              )}
              {trainingMarathon === "multiple" ? (
                <textarea
                  className="form-control"
                  name="trainingMultipleMarathon"
                  id="inputTrainingMultipleMarathon"
                  cols="30"
                  rows="3"
                  placeholder="Please tell us all the marathons you will be running."
                  value={trainingMultipleMarathon}
                  onChange={this.onChange}
                ></textarea>
              ) : (
                ""
              )}
            </div>
            {/* <div className="mb-3">
              <label htmlFor="inputWornLululemonBefore" className="form-label">
                Have you worn lululemon before?
              </label>
              <select
                className="form-select"
                name="wornLululemonBefore"
                id="inputWornLululemonBefore"
                value={wornLululemonBefore}
                onChange={this.onChange}
              >
                <option value="default">Choose one...</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="inputTopSize" className="form-label">
                Please choose your top size?
              </label>
              <select
                className="form-select"
                name="topSize"
                id="inputTopSize"
                value={topSize}
                onChange={this.onChange}
              >
                <option value="default">Choose one...</option>
                <option value="Men's - XS">Men's - XS</option>
                <option value="Men's - S">Men's - S</option>
                <option value="Men's - M">Men's - M</option>
                <option value="Men's - L">Men's - L</option>
                <option value="Men's - XL">Men's - XL</option>
                <option value="Men's - XXL">Men's - XXL</option>
                <option value="Women's - 2">Women's - 2</option>
                <option value="Women's - 4">Women's - 4</option>
                <option value="Women's - 6">Women's - 6</option>
                <option value="Women's - 8">Women's - 8</option>
                <option value="Women's - 10">Women's - 10</option>
                <option value="Women's - 12">Women's - 12</option>
                <option value="Women's - 14">Women's - 14</option>
                <option value="Women's - 16">Women's - 16</option>
                <option value="Women's - 18">Women's - 18</option>
                <option value="Women's - 20">Women's - 20</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="inputBraSize" className="form-label">
                Please choose your bra size? (optional)
              </label>
              <select
                className="form-select"
                name="braSize"
                id="inputBraSize"
                value={braSize}
                onChange={this.onChange}
              >
                <option value="default">Choose one...</option>
                <option value="Women's - 2">Women's - 2</option>
                <option value="Women's - 4">Women's - 4</option>
                <option value="Women's - 6">Women's - 6</option>
                <option value="Women's - 8">Women's - 8</option>
                <option value="Women's - 10">Women's - 10</option>
                <option value="Women's - 12">Women's - 12</option>
                <option value="Women's - 14">Women's - 14</option>
                <option value="Women's - 16">Women's - 16</option>
                <option value="Women's - 18">Women's - 18</option>
                <option value="Women's - 20">Women's - 20</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="inputShortSize" className="form-label">
                Please choose your short size?
              </label>
              <select
                className="form-select"
                name="shortSize"
                id="inputShortSize"
                value={shortSize}
                onChange={this.onChange}
              >
                <option value="default">Choose one...</option>
                <option value="Men's - XS">Men's - XS</option>
                <option value="Men's - S">Men's - S</option>
                <option value="Men's - M">Men's - M</option>
                <option value="Men's - L">Men's - L</option>
                <option value="Men's - XL">Men's - XL</option>
                <option value="Men's - XXL">Men's - XXL</option>
                <option value="Women's - 2">Women's - 2</option>
                <option value="Women's - 4">Women's - 4</option>
                <option value="Women's - 6">Women's - 6</option>
                <option value="Women's - 8">Women's - 8</option>
                <option value="Women's - 10">Women's - 10</option>
                <option value="Women's - 12">Women's - 12</option>
                <option value="Women's - 14">Women's - 14</option>
                <option value="Women's - 16">Women's - 16</option>
                <option value="Women's - 18">Women's - 18</option>
                <option value="Women's - 20">Women's - 20</option>
              </select>
            </div> */}
            <p className="text-danger small">
              ** after clicking the update button. Please refresh the page to see
              updated info.
            </p>
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#missionCompleteModal"
              style={{ width: "350px" }}
            >
              Update My Info
            </button>
          </div>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}


const Modal = () => {
  return (
    <div
      className="modal fade"
      id="missionCompleteModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Account Updated!
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>Your account has been updated. </p> 
            <p>Please refresh your browser to see the changes.</p> 
          </div>
        </div>
      </div>
    </div>
  );
};


export default withFirebase(UpdateUser);