import app from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/database'


const config = {
  apiKey: "AIzaSyDzaRYQu8b6plko6500hSZuy-uQQmvwRS0",
  authDomain: "the-noname-program.firebaseapp.com",
  databaseURL: "https://the-noname-program-default-rtdb.firebaseio.com",
  projectId: "the-noname-program",
  storageBucket: "the-noname-program.appspot.com",
  messagingSenderId: "520615755583",
  appId: "1:520615755583:web:6b7aa161efb6da21f35d8a",
  measurementId: "G-KTP02NRLWY",
};


class Firebase {
  constructor() {
    app.initializeApp(config);

    // FIREBASE APIS
    this.auth = app.auth();
    this.db = app.database();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doUpdateUserInfo = (updates) => {

    this.db
      .ref(`users/${this.auth.currentUser.multiFactor.user.uid}`)
      .update(updates);

      console.log("🚀 ~ file: firebase.js:49 ~ Firebase ~ updates:", updates)
    console.log(this.auth.currentUser.multiFactor.user.uid)
  };

  doDeleteUser = (userId) => {
    this.db.ref(`users/${userId}`).remove();
  };

  doApproveUser = (userId) => {
    var updates = {
      isApproved: true,
    };
    this.db.ref(`users/${userId}`).update(updates);
    alert("user is approved.");
  };

  doUpdateMissions = (missions) => {
    var updates = {
      missions: missions,
    };
    this.db
      .ref(`users/${this.auth.currentUser.multiFactor.user.uid}`)
      .update(updates);
    console.log("🚀 ~ file: firebase.js:52 ~ Firebase ~ missions:", missions);
    // console.log(this.auth.currentUser.multiFactor.user.uid)
  };

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = (uid) => this.db.ref(`users/${uid}`);
  users = () => this.db.ref("users");
};

export default Firebase;