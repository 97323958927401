import React, { Component } from 'react'; 
import { withRouter } from 'react-router-dom'; 

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';


const SignInPage = () => (
  <div className='container d-flex flex-column align-items-center' style={{height: '70vh'}}>
    <h1>SignIn</h1>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);

const INITIAL_STATE = { 
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component { 
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.ACCOUNT);
      })
      .catch((error) => {
        this.setState({ error });
      });
    
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }; 

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>

        <div className="mb-3">
          <label htmlFor="inputEmail" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="inputEmail"
            aria-describedby="emailHelp"
            name="email"
            value={email}
            onChange={this.onChange}
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="inputPassword" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="inputPassword"
            name="password"
            value={password}
            onChange={this.onChange}
          />
        </div>

        <button disabled={isInvalid} type="submit" className="btn btn-primary">
          Sign In
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  } 
}

const SignInForm = withRouter(withFirebase(SignInFormBase));
export default SignInPage; 
export { SignInForm };