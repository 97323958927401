import React from "react";
import { withFirebase } from "../Firebase";



const SignOutButton = ({ firebase }) => (
  <button type="button" className='btn btn-outline-dark' onClick={firebase.doSignOut} style={{width: '150px'}}>
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
