import React, { useState } from "react";
import Calendar from 'react-calendar';
import { differenceInCalendarDays } from "date-fns";
import noNameLogo from '../../Media/noname_Logo_4C.png';

import '../../styles/home.css';
import { withAuthorization } from "../Session";
import workoutData  from "./workoutData";


const todaysDate = new Date();

const HomePage = () => {
  const [date, setDate] = useState(new Date());

  const onChange = (date) => {
    setDate(date);
  }

  const datesToAddClassTo = () => {
    let dates = [];
    workoutData.forEach((workout) => {
      workout.body.forEach((el) => {
        dates.push(new Date(el.date));
      });
    });
    return dates;
  }
  
  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;

  }
  function tileClassName({ date, view }) {
    // Add class to tiles in month view only
    if (view === "month") {
      var dates = datesToAddClassTo();
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (dates.find((dDate) => isSameDay(dDate, date))) {
        return "workoutDay";
      }
    }
  }

  const handleClickScroll = (date) => {
    const stringDate = date.toLocaleDateString();
    const element = document.getElementById(stringDate);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  function ProgramPlaceHolder() {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{height: '70vh'}}>
        <h1>Launching July 11th</h1>
      </div>
    )
  }


  return (
    <div className="d-flex flex-column align-items-center ">
      <ProgramPlaceHolder />
      {/* <h1 className="titleText">The <img src={noNameLogo} alt="noname logo" style={{width: '140px'}} /> Program</h1>
      <Calendar
        onChange={onChange}
        value={date}
        tileClassName={tileClassName}
        onClick={handleClickScroll(date)}
      />
      <h1 className="my-3">Todays Date is: {todaysDate.toLocaleDateString()}</h1>

      <div className="workouts">{workouts(workoutData)}</div> */}
    </div>
  );
}

const workouts = (workouts) => {
  return workouts.map((workout, index) => (
    <div className="card my-5">
      <div className="card-body">
      <h1 className="text-center bg-light" key={index}>{workout.week}</h1>
      {workout.body.map((el, index) => {
        return (
          <div id={el.date} className="my-4" key={el.date}>
            <h2 className="card-subtitle mb-2 text-muted">
              <strong>{el.date}</strong>
            </h2>
            <h1>{el.type}</h1>
            {el.description &&
              <h2>
                <strong>Details:</strong> {el.description}
              </h2>
            }
            {el.start &&
            <h2>
              <strong>Location:</strong> {el.start}
            </h2>}
            {el.finish && 
            <h2>
              <strong>Location:</strong> {el.finish}
            </h2>}
           {el.timeframe && <h2>
              <strong>When:</strong> {el.timeframe}
            </h2>}
          </div>
        );
      })}

      </div>
    </div>
  ));
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(HomePage);
