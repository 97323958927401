import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import * as bootstrap from "bootstrap";
import { CSVLink } from "react-csv";

import "../../styles/admin.css";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
// import { UserList, UserItem } from '../Users';
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";

const AdminPage = () => (
  <div>
    <h1 className="text-center">Admin Page:</h1>

    <Switch>
      <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
      <Route exact path={ROUTES.ADMIN} component={UserList} />
    </Switch>
  </div>
);

class UserListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on("value", (snapshot) => {
      const usersObject = snapshot.val();
      const usersList = Object.keys(usersObject).map((key) => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    const headers = [
      { label: "User Name", key: "username" },
      { label: "Nickname", key: "nickname" },
      { label: "Pronouns", key: "pronouns" },
      { label: "Phone", key: "phone" },
      { label: "Email", key: "email" },
      { label: "Sign Up Date", key: "signUpDate" },
      //  { label: "Emergency Contact", key: "emergencyContact" },
      //  { label: "Emergency Phone", key: "emergencyPhone" },
      //  { label: "Date of Birth", key: "DOB" },
      { label: "Instagram Handle", key: "instaHandle" },
      { label: "Accessability Needs", key: "accessabilityNeeds" },
      //  { label: "Rnning Crew", key: "runningCrew" },
      { label: "Previously Run Marathons", key: "previousMarathons" },
      { label: "Current Marathon", key: "trainingMarathon" },
      { label: "Current Marathon Other", key: "trainingOtherMarathon" },
      { label: "Current Marathon Multiple", key: "trainingMultipleMarathon" },
      //  { label: "Wears Lululemon", key: "wornLululemonBefore" },
      //  { label: "Top Size", key: "topSize" },
      //  { label: "Bra Size", key: "braSize" },
      //  { label: "Short Size", key: "shortSize" },
      { label: "Mission_Post", key: "missions.posted" },
      { label: "Mission_Answer", key: "missions.answer" },
      { label: "isApproved", key: "isApproved" },
    ];

    const csvReport = {
      filename: "NoNameSignUpList.csv",
      headers: headers,
      data: users,
    };

    return (
      <div className="container">
        <h2>Users:</h2>
        {loading && <div>Loading ...</div>}
        <CSVLink {...csvReport}>
          <button className="btn btn-primary">Download Users List</button>
        </CSVLink>

        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              {/* <th scope="col">ID</th> */}
              <th scope="col">Username</th>
              <th scope="col">Email</th>
              <th scope="col">Date Joined</th>
              <th scope="col">Missions</th>
              <th scope="col">Details</th>
            </tr>
          </thead>
          {users.map((user, index) => (
            <tbody key={user.uid}>
              <tr className={`${user.isApproved ? "table-success" : ""}`}>
                <th scope="row">{index + 1}</th>
                {/* <td>{user.uid}</td> */}
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{!user.signUpDate ? "early adopter" : user.signUpDate}</td>
                <td>{user.missions ? "Completed" : ""}</td>
                <td>
                  <Link
                    to={{
                      pathname: `${ROUTES.ADMIN}/${user.uid}`,
                      state: { user },
                    }}
                  >
                    Details
                  </Link>
                </td>
              </tr>

              {/* <span>
                  <strong>ID:</strong> {user.uid}
                </span>
                <span className="mx-3">
                  <strong>Username:</strong> {user.username}
                </span>
                <span className="mx-3">
                  <strong>E-Mail:</strong> {user.email}
                </span>
                {user.isApproved ? (
                  <span className="mx-3">
                    <strong className="text-success">Approved</strong>
                  </span>
                ) : (
                  ""
                )}
                <span>
                  <Link
                    to={{
                      pathname: `${ROUTES.ADMIN}/${user.uid}`,
                      state: { user },
                    }}
                  >
                    Details
                  </Link>
                </span> */}
            </tbody>
          ))}
        </table>
      </div>
    );
  }
}

class UserItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .user(this.props.match.params.id)
      .on("value", (snapshot) => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        });
      });
  }

  onDeleteUser = () => {
    this.props.firebase.doDeleteUser(this.props.match.params.id);
    console.log(this.state.user.username);
    this.props.history.push(ROUTES.ADMIN);
  };

  onApproveUser = () => {
    if (!this.state.user.isApproved) {
      this.props.firebase.doApproveUser(this.props.match.params.id);

      this.props.firebase
        .user(this.props.match.params.id)
        .on("value", (snapshot) => {
          this.setState({
            user: snapshot.val(),
          });
          console.log(snapshot.val());
          console.log("state:", this.state.user);
        });
    } else {
      alert("User has ALREADY been approved");
    }
  };

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  render() {
    const { user, loading } = this.state;

    return (
      <div>
        {/* <h2>User ({this.props.match.params.id})</h2> */}
        {loading && <div>Loading ...</div>}

        <Modal deleteUser={this.onDeleteUser} userName={user.username} />
        {user.isApproved && (
          <div className="container d-flex flex-column text-success text-center h1">
            Runner is approved
          </div>
        )}
        {user && (
          <div className="container d-flex flex-column align-items-start">
            <span>
              <strong>ID:</strong> {user.uid}
            </span>
            <span>
              <strong>E-Mail:</strong> {user.email}
            </span>
            <span>
              <strong>Username:</strong> {user.username}
            </span>
            <span>
              <strong>Nickname:</strong> {user.nickname}
            </span>
            <span>
              <strong>Email:</strong> {user.email}
            </span>
            <span>
              <strong>Join Date:</strong>{" "}
              {!user.signUpDate ? "early adopter" : user.signUpDate}
            </span>
            <span>
              <strong>Pronouns:</strong> {user.pronouns}
            </span>
            <span>
              <strong>Phone:</strong> {user.phone}
            </span>
            {/* <span>
              <strong>Emergency Contact: </strong>
              {user.emergencyContact}
            </span>
            <span>
              <strong>Emergency Phone: </strong>
              {user.emergencyPhone}
            </span> */}
            <span>
              <strong>Instagram Handle:</strong> {user.instaHandle}
            </span>
            <span>
              <strong>Accessability Needs:</strong> {user.accessabilityNeeds}
            </span>
            {/* <span>
              <strong>Running Crew:</strong> {user.runningCrew}
            </span> */}
            <span>
              <strong>Previous Marathons:</strong> {user.previousMarathons}
            </span>
            <span>
              <strong>Current Marathon:</strong> {user.trainingMarathon}
            </span>
            {user.trainingMarathon === "other" ? (
              <span>
                {" "}
                <strong>Current Marathon Other:</strong>{" "}
                {user.trainingOtherMarathon}{" "}
              </span>
            ) : null}
            {user.trainingMarathon === "multiple" ? (
              <span>
                {" "}
                <strong>Current Marathon Multiple:</strong>{" "}
                {user.trainingMultipleMarathon}{" "}
              </span>
            ) : null}
            {/* <span>
              <strong>Top Size:</strong> {user.topSize}
            </span>
            {user.braSize === "" ? null : (
              <span>
                <strong>Bra Size:</strong> {user.braSize}
              </span>
            )}
            <span>
              <strong>Short Size:</strong> {user.shortSize}
            </span> */}
            {user.missions ? (
              <div className="d-flex flex-column align-items-start">
                <span>
                  <strong>Did I post to Insta as my mission: </strong>
                  {user.missions.posted ? "I did post" : "Did not post"}
                </span>
                <span>
                  <strong>What does running mean to you?: </strong>
                  {user.missions.answer === ""
                    ? "I chose not to answer"
                    : user.missions.answer}
                </span>
              </div>
            ) : (
              "MISSIONS NOT COMPLETED"
            )}
            <span>
              <strong>Approved: </strong>
              {user.isApproved ? user.isApproved.toString() : "Not Yet"}
            </span>
            <div className="d-flex flex-column align-items-center w-100">
              <button
                className="btn btn-primary mb-3"
                type="button"
                onClick={this.onSendPasswordResetEmail}
              >
                Send Password Reset
              </button>
              <button
                type="button"
                className="btn btn-primary mb-3"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Delete User
              </button>
              <button
                className={`btn mb-3 ${
                  user.isApproved ? "btn-info" : "btn-success"
                }`}
                type="button"
                onClick={this.onApproveUser}
              >
                {!user.isApproved ? "Approve" : "Already Approved"}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function Modal(props) {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              You are about to delete <strong>{props.userName}</strong>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            Are you sure you want to delete this user?
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={props.deleteUser}
            >
              Delete User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

const condition = (authUser) => authUser && authUser.roles[ROLES.ADMIN];

export default withAuthorization(condition)(AdminPage);
