import React from "react";
import { Link } from 'react-router-dom';
import { AuthUserContext } from "../Session";
import ReactPlayer from "react-player";

import * as ROUTES from "../../constants/routes";
import '../../styles/landing.css';
import landingVideo from "../../Media/NoNameS03.mp4";
import noNameLogoBanner from '../../Media/noname_logo_2024_transparent.png';
import nonameLogo from '../../Media/noname_logo_2024_transparent_cropped.png';

const Landing = () => (
  <div>
    {/* <SplashPage />›› */}
      <Hero />
      <InfoSection />
    <AuthUserContext.Consumer>
      {(authUser) => !authUser &&  <ActionSection />}
    </AuthUserContext.Consumer>
  </div>
);

const Hero = () => (
  <div className="landingpage">
    <video
      src={landingVideo}
      autoPlay={true}
      playsInline={true}
      loop={true}
      muted
      className="video-bg"
    />
    {/* <ReactPlayer
      className="video-bg"
      width="120%"
      height="100%"
      url={landingVideo}
      playing={true}
    /> */}
    <div className="bg-overlay"></div>

    {/* <div className="home-text">
      <img
        src={noNameLogoStacked}
        className="img-fluid"
        alt="noName stacked logo"
        style={{ width: "300px" }}
      />
      <h2>Back to the Streets</h2>
    </div> */}
  </div>
);

const InfoSection = () => (
  <div className="d-flex flex-column align-items-center infoSection">
    <div className="d-flex flex-column align-items-center mb-5 ">
      <img
        src={noNameLogoBanner}
        className="img-fluid"
        alt="noName stacked logo"
        style={{ width: "80vw" }}
      />
    </div>

    <div className="text-center my-5" style={{ maxWidth: "80%" }}>
      <h2 className="card-title mb-3">Run with who you trust</h2>
      <p className="card-text">
        The <img src={nonameLogo} alt="noname logo" style={{width: '100px', marginBottom: '3px'}} /> Program:
        <br />
        A marathon training experience with Coach + Coffey
        <br />
        Led by the best in NYC
        <br />
        16 weeks
        <br />
        3x a week
        <br />
        All paces and experience levels supported
      </p>
    </div>

    <hr style={{ border: "2px solid grey", width: "80%" }} />

    <div className="text-center my-5" style={{ maxWidth: "80%" }}>
      <p className="card-text">
        Miles. Speed. History. Nutrition. Hydration. Psychology. Strength.
        Flexibility. Discovery.
        <br />
        Respect the distance. Respect the community. Respect the body.
        <br />
      </p>
    </div>

    <hr style={{ border: "2px solid grey", width: "80%" }} />
  </div>
);

const ActionSection = () => (
  <div className="d-flex flex-column align-items-center text-center container my-5">
    {/* <p>
      If you’re signed up for a fall marathon, <br />
      and you’re all the way in, <br />
      we want you on our team.
    </p> */}
    <Link className="btn btn-lg btn-primary" to={ROUTES.SIGN_UP}>
      Apply
    </Link>
  </div>
);

const SplashPage = () => (
  <div className="d-flex flex-column justify-content-center align-items-center text-center splash" >
    <h1 className="my-5">noname season 3 is loading...</h1>
    <h3>Follow @thatcoffeyboy + @justrunjulia and stay tuned.</h3>
  </div>
)

export default Landing;
